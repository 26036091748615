import { LogtoProvider } from '@logto/react'
import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import { logtoConfig } from '../constants/app'
import './index.css'

const { Content, Footer } = Layout
function AppLayout() {
  return (
    <LogtoProvider config={logtoConfig}>
      <Layout>
        <Content
          style={{
            minHeight: '100vh',
          }}
        >
          <Outlet />
          {/* <Footer
            style={{
              textAlign: "center",
              backgroundColor: "transparent",
              marginInlineStart: 275,
            }}
          >
            Hika ©{new Date().getFullYear()} Created by Hika Team @Version
            - [{__VERSION__}-{__COMMIT_HASH__}]
          </Footer> */}
        </Content>
      </Layout>
    </LogtoProvider>
  )
}

export default AppLayout
