import {
  IAddToWhitelistApiAdminWhitelistPostData,
  IAddToWhitelistApiAdminWhitelistPostError,
  IAdminUserLoginBody,
  IApplyUpdateQuotaApiQuotaApplyPostData,
  IApplyUpdateQuotaApiQuotaApplyPostError,
  IBodyCreateViaFilesApiKbaseFilesPost,
  IBodyUploadFileApiUploadImagesPost,
  ICanSignupApiUserReferrerCodePostData,
  ICanSignupApiUserReferrerCodePostError,
  ICandidateRequest,
  ICreateKbaseApiKbasePostData,
  ICreateKbaseApiKbasePostError,
  ICreateKbaseQuestBody,
  ICreateUserApiUserPostData,
  ICreateUserApiUserPostError,
  ICreateUserBody,
  ICreateViaFilesApiKbaseFilesPostData,
  ICreateViaFilesApiKbaseFilesPostError,
  ICreateViaLinksApiKbaseLinksPostData,
  ICreateViaLinksApiKbaseLinksPostError,
  ICreateViaXhsApiAdminImportXhsPostData,
  ICreateViaXhsApiAdminImportXhsPostError,
  IDeleteFromWhitelistApiAdminWhitelistDeleteData,
  IDeleteFromWhitelistApiAdminWhitelistDeleteError,
  IDeleteKbaseApiKbaseKidDeleteData,
  IDeleteKbaseApiKbaseKidDeleteError,
  IGenerateReferralCodeApiAdminReferalCodePostData,
  IGenerateReferralCodeApiAdminReferalCodePostError,
  IGenerateReferralCodeApiAdminReferalCodePostParams,
  IGetQuotaStatusApiQuotaApplyStatusPostData,
  IGetQuotaStatusApiQuotaApplyStatusPostError,
  IGetWhitelistApiAdminWhitelistGetData,
  IIsInWhitelistApiAdminWhitelistCheckPostData,
  IIsInWhitelistApiAdminWhitelistCheckPostError,
  IIsInWhitelistApiUserWhitelistInPostData,
  IIsInWhitelistApiUserWhitelistInPostError,
  ILinkRequestBody,
  IListAllReferenceApiKbaseReferencesGetData,
  IListAllReferenceApiKbaseReferencesGetError,
  IListAllReferenceApiKbaseReferencesGetParams,
  IListCandidatesToQueryApiKbaseCandidatePostData,
  IListCandidatesToQueryApiKbaseCandidatePostError,
  IListKbaseReferenceApiKbaseKidReferencesGetData,
  IListKbaseReferenceApiKbaseKidReferencesGetError,
  IListKbaseReferenceApiKbaseKidReferencesGetParams,
  IListKbaseReferenceQuoteApiKbaseQuotaGetData,
  IListKbaseReferenceQuoteApiKbaseQuotaGetError,
  IListKbasesApiKbaseGetData,
  IListKbasesApiKbaseGetError,
  IListKbasesApiKbaseGetParams,
  IListMessagesApiHistoryMessagesPostData,
  IListMessagesApiHistoryMessagesPostError,
  IListSearchHistoryApiHistorySearchTopicIdPostData,
  IListSearchHistoryApiHistorySearchTopicIdPostError,
  IListSearchTopicsApiHistorySearchTopicsPostData,
  IListSearchTopicsApiHistorySearchTopicsPostError,
  ILoginAdminUserInfoApiAdminLoginPostData,
  ILoginAdminUserInfoApiAdminLoginPostError,
  IMessageQuestBody,
  IPersistSearchHistoryApiHistorySearchTopicIdPersistPostData,
  IPersistSearchHistoryApiHistorySearchTopicIdPersistPostError,
  IQueryAllKbaseCountApiKbaseCountGetData,
  IQueryKbaseApiKbaseChatPostData,
  IQueryKbaseApiKbaseChatPostError,
  IQueryRequest,
  IQueryTaskStatusApiKbaseTaskGetData,
  IQueryTaskStatusApiKbaseTaskGetError,
  IQueryTaskStatusApiKbaseTaskGetParams,
  IRemoveTopicSearchHistoryApiHistorySearchTopicIdDeleteData,
  IRemoveTopicSearchHistoryApiHistorySearchTopicIdDeleteError,
  IResetSearchHistoryApiHistorySearchTopicIdResetPostData,
  IResetSearchHistoryApiHistorySearchTopicIdResetPostError,
  IRewardQuotaRouteApiQuotaRewardPostData,
  IRewardQuotaRouteApiQuotaRewardPostError,
  ISearchHistoryCandidateBody,
  ISearchHistoryQuestBody,
  ISearchQueryKbaseApiKbaseSearchQueryPostData,
  ISearchQueryKbaseApiKbaseSearchQueryPostError,
  ISearchRequest,
  IUpdateKbaseApiKbasePatchData,
  IUpdateKbaseApiKbasePatchError,
  IUpdateKbaseQuestBody,
  IUpdateSearchHistoryCandidatesApiHistorySearchTopicIdResponseIdCandidatesPostData,
  IUpdateSearchHistoryCandidatesApiHistorySearchTopicIdResponseIdCandidatesPostError,
  IUpdateTaskApiKbaseUpdateTaskPostData,
  IUpdateTaskApiKbaseUpdateTaskPostError,
  IUpdateTaskRequest,
  IUpdateUserBody,
  IUpdateUserInfoApiUserPatchData,
  IUpdateUserInfoApiUserPatchError,
  IUploadFileApiUploadImagesPostData,
  IUploadFileApiUploadImagesPostError,
  IUserInfoApiUserGetData,
  IUserInfoApiUserGetError,
  IValidateAdminUserBody,
  IValidateAdminUserInfoApiAdminValidateUserPostData,
  IValidateAdminUserInfoApiAdminValidateUserPostError,
  IWebSearchApiKbaseWebPostData,
  IWebSearchApiKbaseWebPostError,
  IWhitelistBody,
  IXSHKeywordsRequestBody,
} from './model'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description This endpoint allows you to add an email to the whitelist
   *
   * @tags admin API
   * @name AddToWhitelistApiAdminWhitelistPost
   * @summary Add Email to Whitelist
   * @request POST:/api/admin/whitelist
   */
  addToWhitelistApiAdminWhitelistPost = (data: IWhitelistBody, params: RequestParams = {}) =>
    this.request<IAddToWhitelistApiAdminWhitelistPostData, IAddToWhitelistApiAdminWhitelistPostError>({
      path: `/api/admin/whitelist`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags quota API
   * @name ApplyUpdateQuotaApiQuotaApplyPost
   * @summary apply to increase kbases' quota
   * @request POST:/api/quota/apply
   */
  applyUpdateQuotaApiQuotaApplyPost = (params: RequestParams = {}) =>
    this.request<IApplyUpdateQuotaApiQuotaApplyPostData, IApplyUpdateQuotaApiQuotaApplyPostError>({
      path: `/api/quota/apply`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Users API
   * @name CanSignupApiUserReferrerCodePost
   * @summary only have referral code can view website
   * @request POST:/api/user/referrer/{code}
   */
  canSignupApiUserReferrerCodePost = (code: string, params: RequestParams = {}) =>
    this.request<ICanSignupApiUserReferrerCodePostData, ICanSignupApiUserReferrerCodePostError>({
      path: `/api/user/referrer/${code}`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * @description 主要测试用. 用户会上传文件链接来创建kbase
   *
   * @tags kbase API
   * @name CreateKbaseApiKbasePost
   * @summary Create a new Kbase
   * @request POST:/api/kbase
   */
  createKbaseApiKbasePost = (data: ICreateKbaseQuestBody, params: RequestParams = {}) =>
    this.request<ICreateKbaseApiKbasePostData, ICreateKbaseApiKbasePostError>({
      path: `/api/kbase`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to create a user
   *
   * @tags Users API
   * @name CreateUserApiUserPost
   * @summary create a user
   * @request POST:/api/user
   */
  createUserApiUserPost = (data: ICreateUserBody, params: RequestParams = {}) =>
    this.request<ICreateUserApiUserPostData, ICreateUserApiUserPostError>({
      path: `/api/user`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags kbase API
   * @name CreateViaFilesApiKbaseFilesPost
   * @summary Create Via Files
   * @request POST:/api/kbase/files
   */
  createViaFilesApiKbaseFilesPost = (data: IBodyCreateViaFilesApiKbaseFilesPost, params: RequestParams = {}) =>
    this.request<ICreateViaFilesApiKbaseFilesPostData, ICreateViaFilesApiKbaseFilesPostError>({
      path: `/api/kbase/files`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags kbase API
   * @name CreateViaLinksApiKbaseLinksPost
   * @summary Create Via Links
   * @request POST:/api/kbase/links
   */
  createViaLinksApiKbaseLinksPost = (data: ILinkRequestBody, params: RequestParams = {}) =>
    this.request<ICreateViaLinksApiKbaseLinksPostData, ICreateViaLinksApiKbaseLinksPostError>({
      path: `/api/kbase/links`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin API
   * @name CreateViaXhsApiAdminImportXhsPost
   * @summary 根据关键词列表, 从本地数据库查找小红书相关数据, 导入到知识库
   * @request POST:/api/admin/import/xhs
   */
  createViaXhsApiAdminImportXhsPost = (data: IXSHKeywordsRequestBody, params: RequestParams = {}) =>
    this.request<ICreateViaXhsApiAdminImportXhsPostData, ICreateViaXhsApiAdminImportXhsPostError>({
      path: `/api/admin/import/xhs`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to delete an email from the whitelist
   *
   * @tags admin API
   * @name DeleteFromWhitelistApiAdminWhitelistDelete
   * @summary Delete from Whitelist
   * @request DELETE:/api/admin/whitelist
   */
  deleteFromWhitelistApiAdminWhitelistDelete = (data: IWhitelistBody, params: RequestParams = {}) =>
    this.request<IDeleteFromWhitelistApiAdminWhitelistDeleteData, IDeleteFromWhitelistApiAdminWhitelistDeleteError>({
      path: `/api/admin/whitelist`,
      method: 'DELETE',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags kbase API
   * @name DeleteKbaseApiKbaseKidDelete
   * @summary Delete a Kbase
   * @request DELETE:/api/kbase/{kid}
   */
  deleteKbaseApiKbaseKidDelete = (kid: string, params: RequestParams = {}) =>
    this.request<IDeleteKbaseApiKbaseKidDeleteData, IDeleteKbaseApiKbaseKidDeleteError>({
      path: `/api/kbase/${kid}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to generate referral code
   *
   * @tags admin API
   * @name GenerateReferralCodeApiAdminReferalCodePost
   * @summary Generate Referral Code
   * @request POST:/api/admin/referal_code
   */
  generateReferralCodeApiAdminReferalCodePost = (
    query: IGenerateReferralCodeApiAdminReferalCodePostParams,
    params: RequestParams = {}
  ) =>
    this.request<IGenerateReferralCodeApiAdminReferalCodePostData, IGenerateReferralCodeApiAdminReferalCodePostError>({
      path: `/api/admin/referal_code`,
      method: 'POST',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * @description class QuotaStatus(Enum): Default = 0 Pending = 1 Approved = 2 Unknown = 3
   *
   * @tags quota API
   * @name GetQuotaStatusApiQuotaApplyStatusPost
   * @summary get quota's status
   * @request POST:/api/quota/apply/status
   */
  getQuotaStatusApiQuotaApplyStatusPost = (params: RequestParams = {}) =>
    this.request<IGetQuotaStatusApiQuotaApplyStatusPostData, IGetQuotaStatusApiQuotaApplyStatusPostError>({
      path: `/api/quota/apply/status`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to get the whitelist
   *
   * @tags admin API
   * @name GetWhitelistApiAdminWhitelistGet
   * @summary Get Whitelist
   * @request GET:/api/admin/whitelist
   */
  getWhitelistApiAdminWhitelistGet = (params: RequestParams = {}) =>
    this.request<IGetWhitelistApiAdminWhitelistGetData, any>({
      path: `/api/admin/whitelist`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to check if an email is in the whitelist
   *
   * @tags admin API
   * @name IsInWhitelistApiAdminWhitelistCheckPost
   * @summary Check if Email is in Whitelist
   * @request POST:/api/admin/whitelist/check
   */
  isInWhitelistApiAdminWhitelistCheckPost = (data: IWhitelistBody, params: RequestParams = {}) =>
    this.request<IIsInWhitelistApiAdminWhitelistCheckPostData, IIsInWhitelistApiAdminWhitelistCheckPostError>({
      path: `/api/admin/whitelist/check`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Users API
   * @name IsInWhitelistApiUserWhitelistInPost
   * @summary check if a user is in whitelist using token
   * @request POST:/api/user/whitelist/in
   */
  isInWhitelistApiUserWhitelistInPost = (params: RequestParams = {}) =>
    this.request<IIsInWhitelistApiUserWhitelistInPostData, IIsInWhitelistApiUserWhitelistInPostError>({
      path: `/api/user/whitelist/in`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags kbase API
   * @name ListAllReferenceApiKbaseReferencesGet
   * @summary List all files or links
   * @request GET:/api/kbase/references
   */
  listAllReferenceApiKbaseReferencesGet = (
    query: IListAllReferenceApiKbaseReferencesGetParams,
    params: RequestParams = {}
  ) =>
    this.request<IListAllReferenceApiKbaseReferencesGetData, IListAllReferenceApiKbaseReferencesGetError>({
      path: `/api/kbase/references`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to query candidates based on a keyword and a language
   *
   * @tags kbase API
   * @name ListCandidatesToQueryApiKbaseCandidatePost
   * @summary List candidates based on a keyword and language
   * @request POST:/api/kbase/candidate
   */
  listCandidatesToQueryApiKbaseCandidatePost = (data: ICandidateRequest, params: RequestParams = {}) =>
    this.request<IListCandidatesToQueryApiKbaseCandidatePostData, IListCandidatesToQueryApiKbaseCandidatePostError>({
      path: `/api/kbase/candidate`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags kbase API
   * @name ListKbaseReferenceApiKbaseKidReferencesGet
   * @summary List a kb's all files or links
   * @request GET:/api/kbase/{kid}/references
   */
  listKbaseReferenceApiKbaseKidReferencesGet = (
    { kid, ...query }: IListKbaseReferenceApiKbaseKidReferencesGetParams,
    params: RequestParams = {}
  ) =>
    this.request<IListKbaseReferenceApiKbaseKidReferencesGetData, IListKbaseReferenceApiKbaseKidReferencesGetError>({
      path: `/api/kbase/${kid}/references`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * @description class QuotaStatus(Enum): Default = 0 Pending = 1 Approved = 2
   *
   * @tags kbase API
   * @name ListKbaseReferenceQuoteApiKbaseQuotaGet
   * @summary List a kb and left quota
   * @request GET:/api/kbase/quota
   */
  listKbaseReferenceQuoteApiKbaseQuotaGet = (params: RequestParams = {}) =>
    this.request<IListKbaseReferenceQuoteApiKbaseQuotaGetData, IListKbaseReferenceQuoteApiKbaseQuotaGetError>({
      path: `/api/kbase/quota`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to list Kbases based on sort, filter and keyword criteria
   *
   * @tags kbase API
   * @name ListKbasesApiKbaseGet
   * @summary List Kbases
   * @request GET:/api/kbase
   */
  listKbasesApiKbaseGet = (query: IListKbasesApiKbaseGetParams, params: RequestParams = {}) =>
    this.request<IListKbasesApiKbaseGetData, IListKbasesApiKbaseGetError>({
      path: `/api/kbase`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags history API
   * @name ListMessagesApiHistoryMessagesPost
   * @summary show msgs
   * @request POST:/api/history/messages/
   */
  listMessagesApiHistoryMessagesPost = (data: IMessageQuestBody, params: RequestParams = {}) =>
    this.request<IListMessagesApiHistoryMessagesPostData, IListMessagesApiHistoryMessagesPostError>({
      path: `/api/history/messages/`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags history API
   * @name ListSearchHistoryApiHistorySearchTopicIdPost
   * @summary show a list of search history
   * @request POST:/api/history/search/{topic_id}
   */
  listSearchHistoryApiHistorySearchTopicIdPost = (
    topicId: string,
    data: ISearchHistoryQuestBody,
    params: RequestParams = {}
  ) =>
    this.request<IListSearchHistoryApiHistorySearchTopicIdPostData, IListSearchHistoryApiHistorySearchTopicIdPostError>(
      {
        path: `/api/history/search/${topicId}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }
    )
  /**
   * No description
   *
   * @tags history API
   * @name ListSearchTopicsApiHistorySearchTopicsPost
   * @summary List all search topics(for sidebar usage)
   * @request POST:/api/history/search/topics
   */
  listSearchTopicsApiHistorySearchTopicsPost = (data: ISearchHistoryQuestBody, params: RequestParams = {}) =>
    this.request<IListSearchTopicsApiHistorySearchTopicsPostData, IListSearchTopicsApiHistorySearchTopicsPostError>({
      path: `/api/history/search/topics`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to validate admin user info
   *
   * @tags admin API
   * @name LoginAdminUserInfoApiAdminLoginPost
   * @summary Log in Admin User
   * @request POST:/api/admin/login
   */
  loginAdminUserInfoApiAdminLoginPost = (data: IAdminUserLoginBody, params: RequestParams = {}) =>
    this.request<ILoginAdminUserInfoApiAdminLoginPostData, ILoginAdminUserInfoApiAdminLoginPostError>({
      path: `/api/admin/login`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags history API
   * @name PersistSearchHistoryApiHistorySearchTopicIdPersistPost
   * @summary persist a search history(topic)
   * @request POST:/api/history/search/{topic_id}/persist
   */
  persistSearchHistoryApiHistorySearchTopicIdPersistPost = (topicId: string, params: RequestParams = {}) =>
    this.request<
      IPersistSearchHistoryApiHistorySearchTopicIdPersistPostData,
      IPersistSearchHistoryApiHistorySearchTopicIdPersistPostError
    >({
      path: `/api/history/search/${topicId}/persist`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags kbase API
   * @name QueryAllKbaseCountApiKbaseCountGet
   * @summary 返回kbase的总数
   * @request GET:/api/kbase/count
   */
  queryAllKbaseCountApiKbaseCountGet = (params: RequestParams = {}) =>
    this.request<IQueryAllKbaseCountApiKbaseCountGetData, any>({
      path: `/api/kbase/count`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags kbase API
   * @name QueryKbaseApiKbaseChatPost
   * @summary Chat with Kbase
   * @request POST:/api/kbase/chat
   */
  queryKbaseApiKbaseChatPost = (data: IQueryRequest, params: RequestParams = {}) =>
    this.request<IQueryKbaseApiKbaseChatPostData, IQueryKbaseApiKbaseChatPostError>({
      path: `/api/kbase/chat`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description 调用links, file上传, 成功会返回一个任务id , 然后拿这个任务id去查询任务状态
   *
   * @tags kbase API
   * @name QueryTaskStatusApiKbaseTaskGet
   * @summary 拿这个任务id去查询 任务状态
   * @request GET:/api/kbase/task
   */
  queryTaskStatusApiKbaseTaskGet = (query: IQueryTaskStatusApiKbaseTaskGetParams, params: RequestParams = {}) =>
    this.request<IQueryTaskStatusApiKbaseTaskGetData, IQueryTaskStatusApiKbaseTaskGetError>({
      path: `/api/kbase/task`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags history API
   * @name RemoveTopicSearchHistoryApiHistorySearchTopicIdDelete
   * @summary remove a search history(topic)
   * @request DELETE:/api/history/search/{topic_id}
   */
  removeTopicSearchHistoryApiHistorySearchTopicIdDelete = (topicId: string, params: RequestParams = {}) =>
    this.request<
      IRemoveTopicSearchHistoryApiHistorySearchTopicIdDeleteData,
      IRemoveTopicSearchHistoryApiHistorySearchTopicIdDeleteError
    >({
      path: `/api/history/search/${topicId}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags history API
   * @name ResetSearchHistoryApiHistorySearchTopicIdResetPost
   * @summary reset ttl to 3 days for a search history(topic)
   * @request POST:/api/history/search/{topic_id}/reset
   */
  resetSearchHistoryApiHistorySearchTopicIdResetPost = (topicId: string, params: RequestParams = {}) =>
    this.request<
      IResetSearchHistoryApiHistorySearchTopicIdResetPostData,
      IResetSearchHistoryApiHistorySearchTopicIdResetPostError
    >({
      path: `/api/history/search/${topicId}/reset`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags quota API
   * @name RewardQuotaRouteApiQuotaRewardPost
   * @summary reward a user with quota
   * @request POST:/api/quota/reward
   */
  rewardQuotaRouteApiQuotaRewardPost = (params: RequestParams = {}) =>
    this.request<IRewardQuotaRouteApiQuotaRewardPostData, IRewardQuotaRouteApiQuotaRewardPostError>({
      path: `/api/quota/reward`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags kbase API
   * @name SearchQueryKbaseApiKbaseSearchQueryPost
   * @summary This endpoint should be call in two cases: 1. Get a list of candidate string via candidate endpoint, then query these candidatas one by one via this endpoint; 2. Use in chat with kbase scenario.
   * @request POST:/api/kbase/search_query
   */
  searchQueryKbaseApiKbaseSearchQueryPost = (data: IQueryRequest, params: RequestParams = {}) =>
    this.request<ISearchQueryKbaseApiKbaseSearchQueryPostData, ISearchQueryKbaseApiKbaseSearchQueryPostError>({
      path: `/api/kbase/search_query`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags kbase API
   * @name UpdateKbaseApiKbasePatch
   * @summary Update Kbase
   * @request PATCH:/api/kbase
   */
  updateKbaseApiKbasePatch = (data: IUpdateKbaseQuestBody, params: RequestParams = {}) =>
    this.request<IUpdateKbaseApiKbasePatchData, IUpdateKbaseApiKbasePatchError>({
      path: `/api/kbase`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags history API
   * @name UpdateSearchHistoryCandidatesApiHistorySearchTopicIdResponseIdCandidatesPost
   * @summary set candidates for topic's response
   * @request POST:/api/history/search/{topic_id}/{response_id}/candidates
   */
  updateSearchHistoryCandidatesApiHistorySearchTopicIdResponseIdCandidatesPost = (
    topicId: string,
    responseId: string,
    data: ISearchHistoryCandidateBody,
    params: RequestParams = {}
  ) =>
    this.request<
      IUpdateSearchHistoryCandidatesApiHistorySearchTopicIdResponseIdCandidatesPostData,
      IUpdateSearchHistoryCandidatesApiHistorySearchTopicIdResponseIdCandidatesPostError
    >({
      path: `/api/history/search/${topicId}/${responseId}/candidates`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update task status after processing
   *
   * @tags kbase API
   * @name UpdateTaskApiKbaseUpdateTaskPost
   * @summary Update task status
   * @request POST:/api/kbase/update_task
   */
  updateTaskApiKbaseUpdateTaskPost = (data: IUpdateTaskRequest, params: RequestParams = {}) =>
    this.request<IUpdateTaskApiKbaseUpdateTaskPostData, IUpdateTaskApiKbaseUpdateTaskPostError>({
      path: `/api/kbase/update_task`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to Update user info
   *
   * @tags Users API
   * @name UpdateUserInfoApiUserPatch
   * @summary Update User Info
   * @request PATCH:/api/user
   */
  updateUserInfoApiUserPatch = (data: IUpdateUserBody, params: RequestParams = {}) =>
    this.request<IUpdateUserInfoApiUserPatchData, IUpdateUserInfoApiUserPatchError>({
      path: `/api/user`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags upload files API
   * @name UploadFileApiUploadImagesPost
   * @summary Upload an image
   * @request POST:/api/upload/images
   */
  uploadFileApiUploadImagesPost = (data: IBodyUploadFileApiUploadImagesPost, params: RequestParams = {}) =>
    this.request<IUploadFileApiUploadImagesPostData, IUploadFileApiUploadImagesPostError>({
      path: `/api/upload/images`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to get user info
   *
   * @tags Users API
   * @name UserInfoApiUserGet
   * @summary Get User Info
   * @request GET:/api/user
   */
  userInfoApiUserGet = (params: RequestParams = {}) =>
    this.request<IUserInfoApiUserGetData, IUserInfoApiUserGetError>({
      path: `/api/user`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to validate admin user info
   *
   * @tags admin API
   * @name ValidateAdminUserInfoApiAdminValidateUserPost
   * @summary Validate Admin User
   * @request POST:/api/admin/validate_user
   */
  validateAdminUserInfoApiAdminValidateUserPost = (data: IValidateAdminUserBody, params: RequestParams = {}) =>
    this.request<
      IValidateAdminUserInfoApiAdminValidateUserPostData,
      IValidateAdminUserInfoApiAdminValidateUserPostError
    >({
      path: `/api/admin/validate_user`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description This endpoint allows you to perform web search based on a keyword and language to return the results. Supports both streaming and non-streaming responses.
   *
   * @tags kbase API
   * @name WebSearchApiKbaseWebPost
   * @summary Web search based on a keyword and language
   * @request POST:/api/kbase/web
   */
  webSearchApiKbaseWebPost = (data: ISearchRequest, params: RequestParams = {}) =>
    this.request<IWebSearchApiKbaseWebPostData, IWebSearchApiKbaseWebPostError>({
      path: `/api/kbase/web`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
