import { Button, Divider, Modal } from 'antd'
import styles from '../index.module.scss'

const WelcomeModal = (props) => {
  return (
    <Modal
      title={_t('welcomeToHika')}
      open={true}
      onCancel={props.onCancel}
      closable={false}
      className={styles.welcomeModal}
      style={{ borderRadius: 10 }}
      width={790}
      footer={[
        <Button key="close" size="large" onClick={props.onCancel} shape="round" style={{ width: 120 }}>
          {_t('close')}
        </Button>,
      ]}
    >
      <div style={{ marginTop: 15, lineHeight: '26px' }}>
        <p>{_t('betaTestThanks')}</p>
        <p>{_t('hikaDescription')}</p>
        <p style={{ marginTop: 30 }}>{_t('betaFeatures')}</p>
        <ul>
          {_t('featureList', { returnObjects: true }).map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <div style={{ marginTop: 30, marginBottom: 30 }}>{_t('betaDisclaimer')}</div>
        <div>{_t('hikaTeam')}</div>
        <Divider />
      </div>
    </Modal>
  )
}

export default WelcomeModal
