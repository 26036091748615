import apis from '@apis/index'
import useLayoutContext from '@hooks/useLayoutContext'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getCacheManager } from '../../utils'
import { reportEventTracking } from '@utils/tracking'
import useTopicId from '@hooks/useTopicId'

const cacheManager = getCacheManager('global_kbase_search')

export default function useSearchLogic() {
  const [questions, setQuestions] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const { currentTopic, setCurrentTopic } = useLayoutContext()
  const question = searchParams.get('question')

  const topicId = useTopicId()

  const onSearch = (question: string) => {
    reportEventTracking('seach_result_ask_question')

    setQuestions((list) => [{ key: Date.now(), question }, ...list])
  }

  useEffect(() => {
    if (question?.trim()) {
      searchParams.delete('question')
      setSearchParams(Object.fromEntries(searchParams))
      onSearch(decodeURIComponent(question))
    }
  }, [question, searchParams])

  useEffect(() => {
    const topicId = currentTopic?.topic_id

    if (!topicId) return

    if (topicId && !currentTopic._isNewSearch) {
      apis
        .listSearchHistoryApiHistorySearchTopicIdPost(topicId, { limit: 500 }, {})
        .then((res) => res.json())
        .then((res) => {
          setQuestions([])
          setCurrentTopic((cur) => {
            if (cur.topic_id !== topicId) return cur

            cur._history = res.data || []
            return { ...cur }
          })
        })
    }

    // delete currentTopic._isNewSearch

    return () => {
      setQuestions([])
    }
  }, [currentTopic?.topic_id])

  return {
    questions,
    currentTopic,
    onSearch,
    topicId,
    question,
  }
}
