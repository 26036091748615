import { useLogto } from '@logto/react'
import { createContext, useEffect, useState } from 'react'
import { API_URL } from '../../../constants/api'
import useUser from '../../../hooks/useUser'
import apis from '@apis/index'
import { requestFn } from '../../../utils/request'
import { useNavigate } from 'react-router-dom'
import routes from '@constants/routes'
import LoginPage from '../../../pages/login'
import { Spin } from 'antd'
export const AuthContext = createContext<{
  token?: string
  user?: any
  updateUserInfo?: any
}>({ token: null })

// const sessionKey = 'user_token'
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState('')
  const { isAuthenticated, getAccessToken, isLoading } = useLogto()
  const userState = useUser()
  const navigate = useNavigate()
  const [isValidating, setIsValidating] = useState(true)
  const [isRequesting, setIsRequesting] = useState(false)
  const [error, setError] = useState(null)

  const refreshToken = async () => {
    const accessToken = (await getAccessToken(API_URL)) as string
    setToken(accessToken)
  }

  useEffect(() => {
    if (isLoading || error || isRequesting) return

    const fetchToken = async () => {
      try {
        if (isAuthenticated) {
          refreshToken()
          setIsValidating(false)
          // accessToken && localStorage.setItem(sessionKey, accessToken)
          if (routes.INVITE_PAGE === location.pathname) return
          setIsRequesting(true)
          const isInWhitelist = await requestFn(apis.isInWhitelistApiUserWhitelistInPost)
          if (!isInWhitelist) {
            navigate(routes.INVITE_PAGE, { replace: true })
          }
        } else {
          setToken('')
          // localStorage.removeItem(sessionKey)
          navigate(routes.LOGIN, { replace: true })
        }
      } catch (error) {
        setError(error)
      }
    }

    fetchToken()
  }, [isAuthenticated, isLoading, getAccessToken, error, navigate])

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }
    const timer = setInterval(refreshToken, 5 * 60 * 1e3)
    return () => {
      clearInterval(timer)
    }
  }, [isAuthenticated])

  return (
    <AuthContext.Provider value={{ token, ...userState }}>
      {isAuthenticated && !isValidating ? (
        children
      ) : isLoading || isValidating ? (
        <div className="flex w-screen h-screen justify-center items-center">
          <Spin />
        </div>
      ) : (
        <LoginPage />
      )}
    </AuthContext.Provider>
  )
}
