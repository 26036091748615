import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

export function MarkdownReply(props) {
  return (
    <div className="prose" style={{ maxWidth: 'none' }}>
      <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} {...props} />
    </div>
  )
}
