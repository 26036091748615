import { IKbaseDict, IOriginalDict } from '../apis/generated/model'

export enum ICreateProgressState {
  requesting = 'requesting',

  pending = 'pending',
  queued = 'queued',
  retrying = 'retrying',
  processing = 'processing',
  complete = 'completed',
  failed = 'failed',
}

export interface IStreamChunk {
  loading: boolean
  data: {
    answer?: string
    error?: string

    //kbase search
    kbase?: IKbaseDict
    //web search
    topic_id?: string
    count?: number
    references?: IOriginalDict[]
  }

  token?: string | number
}

export enum IKbaseRefStatus {
  Fail = 0,
  Success = 1,
  Processing = 2,
}

export enum IKbaseStatus {
  Success = 0,
  Fail = 1,
  Processing = 2,
}

export enum IMPORT_TYPE {
  NIL = '',
  FILE = 'file',
  LINK = 'link',
}

export enum Quota_Status {
  Default = 0,
  Pending = 1,
  Approved = 2,
  Unknown = 3,
}
