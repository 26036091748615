import { IKbaseQuota } from '@apis/generated/model'
import apis from '@apis/index'
import FileImportButton from '@components/import-button/file-import-button'
import KbaseLinkImport, { CANCEL_LINK_IMPORT } from '@components/import-button/kbase-link-import'
import LinkImportButton from '@components/import-button/link-import-button'
import { eventsMap } from '@constants/events'
import { Button, Divider, Space, Typography } from 'antd'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import AnimWrapper from '../../components/anim-wrapper'
import KbaseCreation from '../../components/kbase-file-creation'
import { Login } from '../../layout/sidebar/login'
import { IMPORT_TYPE, Quota_Status } from '../../types/kbase'
import { getLocalStorageManager } from '../../utils'
import eventBus from '../../utils/event'
import { requestFn } from '../../utils/request'
import styles from './index.module.scss'
import { reportEventTracking } from '@utils/tracking'

const KEY = 'tip_upload_kbase_info'
const storageHandler = getLocalStorageManager(KEY)

enum IMPORT_TARGET {
  NIL,
  NEW_KBASE,
  EXISTING_KBASE,
}

export default function HomeKbaseImport(props) {
  const animRef = React.useRef<any>()
  const [tipVisible, setTipVisible] = React.useState<boolean>(!storageHandler.getItem())
  const [importType, setImportType] = React.useState(IMPORT_TYPE.NIL)
  const [inputVisible, setInputVisible] = React.useState(false)
  const [importTarget, setImportTarget] = React.useState(null)
  const { onUploadStatusChange, isCreatingLink, isCreatingFile, isAuthenticated, isUploading } = props
  const [isAllowedNew, setIsAllowedNew] = React.useState(true)
  const [enableImportExisting, setEnableImportExisting] = React.useState(true)
  const [pending, setPending] = React.useState(false)
  const [kbasesQuota, setKbasesQuota] = React.useState<IKbaseQuota>(null)
  const [importTargetKid, setImportTargetKid] = React.useState(null)
  const [noKbases, setNoKbases] = React.useState(false)

  const importTargetHandler = (target: IMPORT_TARGET) => {
    reportEventTracking('home_click_import_target', { target })

    if (target === IMPORT_TARGET.EXISTING_KBASE) {
      if (pending || !enableImportExisting) return
    }

    if (target === IMPORT_TARGET.NEW_KBASE) {
      if (!isAllowedNew) return

      if (importType === IMPORT_TYPE.LINK) {
        setInputVisible(true)
      }
    }

    setImportTarget(target)
  }

  const applyForMoreImportQuota = async (event) => {
    reportEventTracking('home_apply_for_more_import_quota')
    event.stopPropagation()
    requestFn(apis.applyUpdateQuotaApiQuotaApplyPost())
    setPending(true)
  }

  const reset = () => {
    reportEventTracking('home_cancel_import')
    setInputVisible(false)
    setImportType(IMPORT_TYPE.NIL)
    setImportTarget(IMPORT_TARGET.NIL)
    setImportTargetKid(null)
  }

  const decideImportType = (type: IMPORT_TYPE) => {
    reportEventTracking('home_click_import_type', { type })
    reset()
    setTipVisible(false)
    if (type === IMPORT_TYPE.LINK && noKbases) {
      setInputVisible(true)
    }
    setImportType(type)
  }

  const onUploadCallback = (...args) => {
    const phase = args[0]
    if (phase === 'start') {
      animRef.current?.run()
    }
    onUploadStatusChange(...args)
    reset()
  }

  const fetchKbasesQuota = () => {
    requestFn(apis.listKbaseReferenceQuoteApiKbaseQuotaGet).then((res: IKbaseQuota) => {
      const left = res?.total_kbase_quota - res?.kbase_quota_list?.length
      let kbaseLeftQuota = 0
      for (const item of res.kbase_quota_list) {
        item.left = item.left <= 0 ? 0 : item.left
        kbaseLeftQuota = Math.max(kbaseLeftQuota, item.left)
      }
      setEnableImportExisting(kbaseLeftQuota > 0)
      setIsAllowedNew(left > 0)
      res.kbase_quota_list.sort((a, b) => b.left - a.left)
      setNoKbases(res?.kbase_quota_list?.length === 0)
      setKbasesQuota(res)
    })
  }

  useEffect(() => {
    fetchKbasesQuota()
    return eventBus.on(eventsMap.CREATE_KBASE_DONE, (data) => {
      fetchKbasesQuota()
    })
  }, [])

  useEffect(() => {
    requestFn(apis.getQuotaStatusApiQuotaApplyStatusPost).then((data) => {
      if (data.status === Quota_Status.Pending) {
        setPending(true)
        setEnableImportExisting(false)
      }
    })
    return eventBus.on(CANCEL_LINK_IMPORT, reset)
  }, [])

  const renderUploader = () => {
    const fileImportButton = (
      <FileImportButton
        active={!noKbases && importType === IMPORT_TYPE.FILE}
        isCreating={isCreatingFile}
        onClick={() => decideImportType(IMPORT_TYPE.FILE)}
        isUploading={isUploading}
      />
    )
    return (
      <div className={clsx('text-center', isUploading && styles.disabledUpload)}>
        <div style={{ color: '#9F9F9F', fontSize: 14, fontWeight: 500 }} className="mb-6">
          {_t('uploadBookPrompt')}
        </div>
        <Space>
          <div style={{ cursor: 'pointer', color: '#9F9F9F', fontSize: 12 }}>
            {noKbases ? (
              <KbaseCreation onUploadStatusChange={onUploadCallback}>{fileImportButton}</KbaseCreation>
            ) : (
              fileImportButton
            )}
          </div>
          <div className="flex">
            <LinkImportButton
              active={importType === IMPORT_TYPE.LINK}
              isCreating={isCreatingLink}
              isUploading={isUploading}
              onClick={() => decideImportType(IMPORT_TYPE.LINK)}
            />
          </div>
        </Space>
      </div>
    )
  }

  const renderImportNewKbase = () => {
    return (
      <div
        className={clsx(styles.importToKbaseBtn, !isAllowedNew && styles.disableImport)}
        onClick={() => importTargetHandler(IMPORT_TARGET.NEW_KBASE)}
      >
        {_t('importNewKbase')} {!isAllowedNew && _t('kbaseLimitReached')}
      </div>
    )
  }

  const renderImportExistingKbase = () => {
    return (
      <div
        className={clsx(styles.importToKbaseBtn, (!enableImportExisting || pending) && styles.disableImport)}
        onClick={() => importTargetHandler(IMPORT_TARGET.EXISTING_KBASE)}
      >
        {_t('importExistingKbase')} {!enableImportExisting && _t('importLimitReached')}
        {!pending && !enableImportExisting && (
          <Button
            type="link"
            onClick={applyForMoreImportQuota}
            size="small"
            style={{ fontSize: 12, padding: 0, height: 18, float: 'right' }}
          >
            {_t('requestMoreImports')}
          </Button>
        )}
        {pending && <label style={{ float: 'right', color: '#1E2336' }}>{_t('requestPending')}</label>}
      </div>
    )
  }

  const renderImportChoices = () => {
    const isFileImport = importType === IMPORT_TYPE.FILE
    if (importTarget === IMPORT_TARGET.EXISTING_KBASE) {
      const renderKbaseList = () => {
        return kbasesQuota?.kbase_quota_list?.length > 0 ? (
          kbasesQuota?.kbase_quota_list?.map((item) => (
            <>
              <li
                className={clsx('flex flex-1', styles.kbaseOpt, item.left <= 0 && styles.disableImport)}
                onClick={(event) => {
                  if (item.left <= 0) {
                    event.stopPropagation()
                    return
                  }
                  reportEventTracking('home_import_target_kid')

                  setImportTargetKid(item.kid)
                  if (!isFileImport) {
                    setInputVisible(true)
                  }
                }}
              >
                <Typography.Paragraph ellipsis style={{ marginBottom: 0, flex: 1, fontSize: 12 }}>
                  {item.nick_name}
                </Typography.Paragraph>
                <label className="ml-auto" style={{ color: '#9F9F9F', fontSize: 12 }}>
                  {_t('importTimesLeft', { count: item.left })}
                </label>
              </li>
              <li style={{ borderBottom: '1px solid #dcdceb' }}></li>
            </>
          ))
        ) : (
          <li
            className={clsx('flex flex-1', styles.kbaseOpt)}
            style={{ cursor: 'default', fontSize: 12 }}
            onClick={(e) => e.stopPropagation()}
          >
            {_t('noAvailableKbase')}
          </li>
        )
      }
      return (
        <div>
          <ul style={{ color: '#1E2336', marginTop: -10 }}>
            {isFileImport ? (
              <KbaseCreation kid={importTargetKid} onUploadStatusChange={onUploadCallback}>
                {renderKbaseList()}
              </KbaseCreation>
            ) : (
              renderKbaseList()
            )}
            <li className={styles.kbaseOpt}>
              <Button type="link" style={{ color: '#1E2336', padding: 0 }} size="small" onClick={reset}>
                {_t('cancel')}
              </Button>
            </li>
          </ul>
        </div>
      )
    }

    return (
      <>
        {isFileImport && isAllowedNew ? (
          <KbaseCreation onUploadStatusChange={onUploadCallback} style={{ height: 18 }}>
            {renderImportNewKbase()}
          </KbaseCreation>
        ) : (
          renderImportNewKbase()
        )}
        <Divider style={{ margin: '8px 0' }} />
        {renderImportExistingKbase()}
        <Divider style={{ margin: '8px 0' }} />
        <Button type="link" style={{ color: '#1E2336', padding: 0 }} size="small" onClick={reset}>
          {_t('cancel')}
        </Button>
      </>
    )
  }

  return (
    <div className={styles.importWrapper}>
      <AnimWrapper ref={animRef} lazy animOption={{ speed: 120 }}>
        <div style={{ marginTop: 40 }} className="flex justify-center">
          <div className={clsx(!isAuthenticated && styles.locked)}>
            {!isAuthenticated && (
              <Login>
                <Button type="primary" shape="round" className={styles.loginBtn}>
                  {_t('loginToUse')}
                </Button>
              </Login>
            )}
            {renderUploader()}
          </div>
        </div>
      </AnimWrapper>
      <div style={{ width: 286, margin: '10px auto', position: 'relative' }}>
        {importType && !inputVisible && !noKbases && (
          <div className={styles.importChoiceWrap}>{renderImportChoices()}</div>
        )}
        {inputVisible && importType === IMPORT_TYPE.LINK && (
          <div style={{ position: 'absolute', left: 0, width: '100%' }}>
            <KbaseLinkImport
              kid={importTargetKid}
              onBeforeSubmit={() => {
                animRef.current?.run()
              }}
            />
          </div>
        )}
        {tipVisible && (
          <div
            style={{
              width: 260,
              borderRadius: 12,
              padding: '10px 15px',
              border: '1px solid #E7E7E7',
              margin: 'auto',
              position: 'absolute',
            }}
          >
            <div className="flex mb-4 justify-center">
              <div>{_t('tip')}</div>
              <Button
                type="text"
                style={{ marginLeft: 'auto', padding: 0, height: 'auto' }}
                onClick={() => {
                  storageHandler.setItem(true)
                  setTipVisible(false)
                }}
              >
                {_t('close')}
              </Button>
            </div>
            <div style={{ color: '#9F9F9F' }}>{_t('importTip')}</div>
          </div>
        )}
      </div>
    </div>
  )
}
