import { Button, Divider, Skeleton, Space, Spin } from 'antd'
import React from 'react'
import useQuestionSearch from '../../hooks/useQuestionSearch'
import AnswerRenderer from '../answer-renderer'
import { ISearchHistoryDict } from '@apis/generated/model'

export function QuestionResult(props) {
  const {
    question,
    goNextAnswer,
    backToPrevAnswer,
    loadingAnswer,
    currentAnswer,
    failGetNext,
    candidates,
    hasNextAnswer,
  } = useQuestionSearch(props)

  // console.log('currentAnswer', currentAnswer, props)
  //TODO: 过滤空响应？
  // if (!currentAnswer?.data?.response) return null
  // if (currentAnswer?.token !== currentAnswerIndex) return null

  const renderAction = () => {
    if (failGetNext) {
      return (
        <div>
          {_t('nextAnswerMissed')}
          {/* <Button type="link" style={{ padding: 0 }} onClick={sendImportRewardHandler}>
            {_t('giftImportTimes')}
          </Button> */}
        </div>
      )
    }

    if (hasNextAnswer) {
      return (
        <Button type="text" style={{ color: '#1E2336' }} onClick={goNextAnswer}>
          {_t('viewNextAnswer')}
        </Button>
      )
    } else {
      return (
        <Button type="text" style={{ color: '#1E2336' }} onClick={backToPrevAnswer}>
          {_t('returnToPreviousAnswer')}
        </Button>
      )
    }
  }
  return (
    <div style={{ marginBottom: 80 }}>
      <div>
        <div
          style={{
            fontSize: 16,
            fontWeight: 600,
            marginLeft: 15,
            marginRight: 15,
          }}
        >
          {question || props.cachedAnswer?.query}
        </div>
        <Divider style={{ margin: '20px 0' }} />
      </div>
      <div>
        <div className="flex center px-3" style={{ marginBottom: 10 }}>
          <div style={{ fontSize: 22, fontWeight: 600 }}>{_t('answer')}</div>
          {candidates.length > 0 && <Space className="ml-auto">{renderAction()}</Space>}
        </div>
        {currentAnswer?.data?.error ||
        (currentAnswer?.data?.answer && !currentAnswer?.data?.loading) ||
        props.cachedAnswer?.response_id ? (
          <AnswerRenderer loading={loadingAnswer} {...currentAnswer} />
        ) : (
          <Skeleton active />
        )}
      </div>
    </div>
  )
}

export default React.memo<{ question?: string; cachedAnswer?: ISearchHistoryDict }>(
  function SearchResult(props) {
    return <QuestionResult {...props} />
  },
  (prev, next) => {
    return (
      (next?.question && prev?.question !== next?.question) ||
      (next?.cachedAnswer?.query && prev?.cachedAnswer?.query !== next?.cachedAnswer?.query)
    )
  }
)
