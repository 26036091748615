import { Layout } from 'antd'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import { Outlet, useLocation } from 'react-router-dom'
import { AuthProvider } from './components/auth-provider'
import SideNavBar from './sidebar'
import MobilePageHeader from './sidebar/index.m'
import './index.css'
import styles from './layout.module.scss'
import { LayoutContextProvider } from '@hooks/useLayoutContext'
import clsx from 'clsx'

const { Content } = Layout
function AuthLayout() {
  const location = useLocation()
  const isSearchResult = /\/search\//.test(location.pathname)

  const height = 'calc(100vh - 40px)'
  return (
    <Layout hasSider style={{ backgroundColor: 'transparent', padding: '20px 20px 0 0' }}>
      <AuthProvider>
        {isMobile ? (
          <div
            className="w-screen h-screen flex justify-center items-center flex-col"
            style={{ fontSize: 20, color: '#000000', marginTop: -100 }}
          >
            <div>{_t('mobileUnsupported')}</div>
            <div style={{ color: '#7E7C99', marginTop: 10, fontSize: 18 }}>{_t('mobileComingSoon')}</div>
          </div>
        ) : (
          <LayoutContextProvider>
            <SideNavBar />
            <Layout style={{ marginInlineStart: 295 }}>
              <Content
                className={clsx('p-4 bg-white relative', isSearchResult && styles.searchResult)}
                style={{
                  borderRadius: 10,
                  minHeight: height,
                  ...(isSearchResult ? { height, maxHeight: height, overflowY: 'auto' } : { overflow: 'initial' }),
                }}
              >
                <Outlet />
              </Content>
            </Layout>
          </LayoutContextProvider>
        )}
      </AuthProvider>
    </Layout>
  )
}

export default AuthLayout
