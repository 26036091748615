import { Space, Typography } from 'antd'
import clsx from 'clsx'
import React, { useCallback, useMemo } from 'react'
import { MarkdownReply } from '../../../../components/reply-md'
import FaviconImage from '../favicon-image'
import styles from './index.module.css'
import { reportEventTracking } from '@utils/tracking'
import eventBus from '@utils/event'
import Link from 'antd/es/typography/Link'
import routes from '@constants/routes'

export interface IAnswerProps {
  answer: string
  count?: number
  error?: string
  references?: any[]

  loading?: boolean
  kbase?: any
}

const xmlParser = (input) => {
  const output = []
  const keywords = []

  let cur = ''
  for (let i = 0; i < input?.length; i++) {
    let ch = input[i]
    if (ch === '<') {
      let tag = ['<']
      while (ch !== '>' && i < input.length) {
        i++
        ch = input[i]
        if (ch === '<') {
          cur += tag.join('')
          //reset
          tag = []
        }
        tag.push(ch)
      }

      const tagStr = tag.join('')
      switch (tagStr) {
        case '<r>':
          break
        case '</r>':
          cur && output.push(cur)
          cur = ''
          break
        case '<s>':
          break
        case '</s>':
          cur && output.push(cur)
          cur = ''
          break
        case '<k>':
          break
        case '</k>':
          cur &&
            keywords.push(
              ...cur
                .split(',')
                .map((k) => k.trim())
                .filter(Boolean)
            )
          cur = ''
          break
        default:
          cur += tagStr
      }
    } else {
      cur += ch
    }
  }
  if (cur !== '') {
    output.push(cur)
  }

  return [output, keywords]
}
export function AnswerOutputRenderer(props: IAnswerProps) {
  const { answer, error, loading } = props
  const [sections, setSections] = React.useState([])
  const [keywords, setKeywords] = React.useState([])

  const parseSections = React.useCallback(async (input) => {
    try {
      const [result, keywords] = xmlParser(input)
      setSections(result)
      setKeywords(keywords)
    } catch (err) {
      console.error('Error parsing XML:', err)
    }
  }, [])

  React.useEffect(() => {
    parseSections(answer)
  }, [answer])
  
  return (
    <div>
      <div>
        {error ? (
          <div>{error}</div>
        ) : !answer && !loading ? (
          <div>{_t('noAnswer')}</div>
        ) : (
          sections?.map?.((sec, i) => {
            if (sec?.trim() === '\n') return null
            return (
              <div key={i} className={clsx('p-4 mb-4', styles.ansSection)}>
                <MarkdownReply>{typeof sec === 'string' ? sec : 'Unknown Error'}</MarkdownReply>
              </div>
            )
          })
        )}
      </div>
      {keywords.length > 0 && (
        <div>
          <Space>
            {keywords.map((k) => (
              <Link
                className="cursor-pointer"
                key={k}
                href={`${routes.TEMP_SEARCH}?question=${encodeURIComponent(k)}`}
                target="_blank"
              >
                {k}
              </Link>
            ))}
          </Space>
        </div>
      )}
    </div>
  )
}

const getIconLink = (url) => {
  try {
    const iconLink = new URL(url ?? '')
    return `${iconLink.origin}/favicon.ico`
  } catch {
    return ''
  }
}

function References(props: IAnswerProps) {
  const refs = useMemo(
    () =>
      props?.references
        .map((ref) => {
          if (!ref.url) return null
          if (!ref.icon) {
            ref.icon = getIconLink(ref.url ?? '')
          }
          return ref
        })
        .filter(Boolean),
    [props.references]
  )

  const viewMoreHandler = useCallback(() => {
    eventBus.emitOnPage('view_more', refs)
  }, [refs])

  return (
    <div className="mt-8">
      <div className="flex mb-2" style={{ padding: '0 20px' }}>
        <div className={styles.refLeft}>{_t('key_links', { count: refs?.slice(0, 2).length })}</div>
        <div className={styles.refRight} onClick={viewMoreHandler}>
          {_t('view_all_links', props)}
        </div>
      </div>
      <div>
        <ul>
          {refs?.slice(0, 2).map((ref) => {
            return (
              <li key={ref.url} className={styles.refItem}>
                <a
                  href={ref.url}
                  target="_blank"
                  className="flex"
                  style={{ fontSize: 16 }}
                  onClick={() => {
                    reportEventTracking('click_reference_link_on_web_search')
                  }}
                >
                  <Space>
                    <FaviconImage iconLink={ref.icon} />
                    {ref.name}
                  </Space>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export function WebAnswerRenderer(props: { data: IAnswerProps }) {
  return (
    <div className={styles.ansWrapper}>
      <AnswerOutputRenderer {...props.data} />
      {props.data?.references?.length > 0 && <References {...props.data} />}
    </div>
  )
}

export function KbaseAnswerRenderer(props: { data: IAnswerProps }) {
  const kbase = props.data?.kbase
  return (
    <div>
      {kbase?.description && (
        <div style={{ marginLeft: 16 }}>
          <div style={{ color: '#1E2336', fontSize: 16 }}>{_t('view_from_perspective', kbase)}</div>
          <Typography.Paragraph>
            <blockquote style={{ color: '#737373A3', fontSize: 16, borderInlineStartWidth: 2 }}>
              {kbase.description}
            </blockquote>
          </Typography.Paragraph>
        </div>
      )}

      <AnswerOutputRenderer {...props.data} />
    </div>
  )
}

export default function AnswerRenderer(props) {
  if (props.data?.kbase?.kid) {
    return <KbaseAnswerRenderer {...props} />
  }

  return <WebAnswerRenderer {...props} />
}
