import SearchInput from '@components/search-input'
import { Divider } from 'antd'
import HomeKbaseImport from './home-kbase-import'
import styles from './index.module.scss'
import useLogic from './useLogic'
import Slogan from '@components/slogan'
import React from 'react'
import { welcomeModalCache } from '@utils/cache'
import WelcomeModal from './components/welcome-modal'

export default function Home() {
  const [isModalVisible, setIsModalVisible] = React.useState(!welcomeModalCache.get())
  const logicHandler = useLogic()
  const { searchHandler } = logicHandler

  const handleClose = () => {
    setIsModalVisible(false)
    welcomeModalCache.set()
  }

  const renderSearchInput = () => {
    return (
      <div style={{ width: '60vw', marginBottom: 40 }} className={styles.searchInput}>
        <SearchInput placeholder={_t('askToExplore')} onPressEnter={searchHandler} />
      </div>
    )
  }

  return (
    <div className="flex justify-center items-center h-full">
      <div className="max-w-screen-lg">
        <Slogan />
        {renderSearchInput()}
        <Divider>{_t('or')}</Divider>
        <HomeKbaseImport {...logicHandler} />
      </div>
      {isModalVisible && <WelcomeModal onCancel={handleClose}/>}
    </div>
  )
}
