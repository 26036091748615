import { useCallback, useState } from 'react'
import { useAuthApi } from '../hooks/useFetch'
import { readStream } from '../utils/request'
import { IStreamChunk } from '../types/kbase'
import EventSourceStream from '@server-sent-stream/web'
import { useParams, useSearchParams } from 'react-router-dom'
import useTopicId from './useTopicId'
import eventBus from '@utils/event'
import { eventsMap } from '@constants/events'

const DefaultValue: IStreamChunk = {
  loading: false,
  data: {
    answer: '',
    count: 0,
    references: [],
  },
}

export function useStreamFetch<T = any>(url) {
  const { authFetch, loading } = useAuthApi(true)
  const [data, setData] = useState<IStreamChunk>(DefaultValue)
  const topic_id = useTopicId()

  const streamFetch = useCallback(
    async (payload: T) => {
      try {
        setData((data) => ({
          ...data,
          ...DefaultValue,
          loading: true,
        }))
        const onDone = () => {
          setData((data) => ({
            ...data,
            done: true,
            loading: false,
          }))
        }
        if (topic_id) {
          payload.topic_id = topic_id
        }
        const response = await authFetch(url, {
          method: 'POST',
          json: {
            stream: 'true',
            ...payload,
          },
        })
        eventBus.emit(eventsMap.WEB_SEARCH_CALLBACK)
        const encoder = new TextEncoder()
        const decoder = new EventSourceStream()
        if (!response.body) return

        response.body.pipeThrough(decoder)
        const reader = decoder.readable.getReader()
        //TODO: kill it after changing page
        while (true) {
          const { value, ...args } = await reader.read()
          if (value.data === '[DONE]') {
            onDone()
            return
          }
          try {
            const parsedVal = JSON.parse(value.data)
            // console.log("parsed val", parsedVal);
            setData((data) => {
              if (parsedVal?.chunk) {
                data.data.answer += parsedVal.chunk
              } else {
                data.data = {
                  ...data.data,
                  ...parsedVal,
                }
              }
              return {
                ...data,
              }
            })
          } catch (e) {
            onDone()
            console.error(e)
          }
        }
        // const readableStream = new ReadableStream({
        //   async start(controller) {
        //     function push() {
        //       reader.read().then(({ done, value }) => {
        //         if (done || value.data === "[DONE]") {
        //           setData((data) => ({
        //             ...data,
        //             loading: false,
        //           }));
        //           controller.close();
        //           return;
        //         }
        //         try {
        //           const parsedVal = JSON.parse(value.data);
        //           console.log("parsed val", parsedVal);
        //           // queue.push(parsedVal);
        //           setData((data) => {
        //             // const parsedVal = queue.shift();
        //             if (parsedVal?.chunk) {
        //               data.data.answer += parsedVal.chunk;
        //             } else {
        //               data.data = {
        //                 ...data.data,
        //                 ...parsedVal,
        //               };
        //             }
        //             return {
        //               ...data,
        //             };
        //           });
        //           // controller.enqueue(encoder.encode(parsedVal.chunk));
        //           push();
        //         } catch (e) {
        //           setData((prev) => ({
        //             ...prev,
        //             loading: false,
        //           }));
        //           console.error(e);
        //           controller.close();
        //         }
        //       });
        //     }

        //     push();
        //   },
        // });

        // return await readStream(response, setOutput);
      } catch (err) {
        setData((prev) => ({
          ...prev,
          loading: false,
        }))
      }
    },
    [authFetch, url, topic_id]
  )

  return { streamFetch, loading, data, setData }
}
