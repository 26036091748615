import { useQueryCandidate } from '@apis/hooks/search'
import { eventsMap } from '@constants/events'
import routes from '@constants/routes'
import useKbaseGlobalQuery from '@hooks/useKbaseGlobalQuery'
import useLayoutContext from '@hooks/useLayoutContext'
import useTopicId from '@hooks/useTopicId'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ITopicHistoryDict } from '../../../apis/generated/model'
import { getCacheManager } from '../../../utils'
import eventBus from '../../../utils/event'
import useWebQuery from './useWebQuery'
import { ISearchHistoryDict } from '@apis/generated/model'
import { IS_NEW_SEARCH } from '@constants/search'
import { setNewSearchTag } from '../../../utils/search'
import { IQueryRequest } from '@apis/generated/model'
import { reportEventTracking } from '@utils/tracking'
import apis from '@apis/index'

interface IQuestionSearch {
  question?: string
  loading?: boolean
  candidates?: any[]

  hasNextAnswer?: boolean
  hasPrevAnswer?: boolean

  goNextAnswer?: () => void
  backToPrevAnswer?: () => void
  loadingAnswer?: boolean
  currentAnswer?: any

  currentTopic?: ITopicHistoryDict

  failGetNext?: boolean
  sendImportRewardHandler?: any
  currentAnswerIndex?: number
}

const cacheManager = getCacheManager('global_kbase_search')

export default function useQuestionSearch(props: {
  cachedAnswer?: ISearchHistoryDict
  question?: string
}): IQuestionSearch {
  const { question, cachedAnswer } = props
  const { searchCandidates } = useQueryCandidate()
  const [candidates, setCandidates] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const { answer: kbaseAnswer, onKbaseGlobalQuery, loading: loadingKA } = useKbaseGlobalQuery()
  const { answer: webAnswer, onWebQuery, loading: loadingWA } = useWebQuery()
  const [answers, setAnswers] = useState<any[]>([])
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState<number>(0)
  const [currentAnswer, setCurrentAnswer] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const topicId = useTopicId()
  const responseId = useRef('')
  const [failGetNext, setFailGetNext] = useState(false)

  const { currentTopic } = useLayoutContext()

  const onSearchCandidates = async (keyword, params) => {
    setLoading(true)
    try {
      //TODO: 按问题存储
      // const output = cacheManager.get(keyword);
      setCandidates([])

      // if (output) {
      //   setCandidates(output);
      //   return;
      // }
      return

      const resp = await searchCandidates({
        keyword,
        response_id: params.data.response_id,
        topic_id: params.data.topic_id,
      })

      if (!resp) return

      //暂时下线知识库查询
      const { reply, language } = resp

      setCandidates(
        reply
          .map((ans) => {
            return {
              ...ans,
              language,
            }
          })
          .slice(0, 1) //TODO: 最多一个知识库
      )
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const updateCurrentAnswer = (v) => {
    setAnswers((list) => {
      const targetIndex = list.findIndex((ans) => ans.token === v?.token)
      if (targetIndex > -1) {
        list.splice(targetIndex, 1, v)
      } else {
        list.push(v)
      }
      return [...list]
    })

    setCurrentAnswer((ans) => {
      if (!ans) return v

      if (v?.token === ans?.token) {
        return v
      }
      return ans
    })
  }

  const goNextAnswer = useCallback(async () => {
    const cur = currentAnswerIndex + 1
    reportEventTracking('click_view_next_answer')

    if (cur > candidates.length) return

    setCurrentAnswerIndex(cur)

    if (answers[cur]) {
      setCurrentAnswer(answers[cur])
      return
    } else {
      const kbase = candidates[cur - 1] //starting from 1
      setCurrentAnswer(null)
      const params: Partial<IQueryRequest> = {
        q: question || cachedAnswer?.query,
      }

      if (responseId.current) {
        params.response_id = responseId.current
      }
      try {
        await onKbaseGlobalQuery(params, kbase, cur)
      } catch {
        setFailGetNext(true)
      }
    }
  }, [candidates, currentAnswerIndex, question, cachedAnswer, answers])

  const backToPrevAnswer = useCallback(() => {
    reportEventTracking('click_view_previous_answer')

    setCurrentAnswerIndex((cur) => {
      if (cur <= 0) return cur

      cur = cur - 1
      setCurrentAnswer(answers[cur])
      return cur
    })
  }, [answers])

  useEffect(() => {
    if (!kbaseAnswer || kbaseAnswer?.token === -1) return

    updateCurrentAnswer(kbaseAnswer)
  }, [kbaseAnswer])

  useEffect(() => {
    if (!webAnswer || !('token' in webAnswer)) return
    updateCurrentAnswer(webAnswer)
  }, [webAnswer])

  useEffect(() => {
    return eventBus.on(eventsMap.WEB_SEARCH_CALLBACK, () => eventBus.emit(eventsMap.REFRESH_TOPIC_LIST))
  }, [])

  useEffect(() => {
    if (!question?.trim() || !!cachedAnswer || loadingWA) return

    eventBus.emitOnPage('start_searching')
    onWebQuery(question, 0).then((ans) => {
      eventBus.emitOnPage('end_searching')
      responseId.current = ans?.data?.response_id
      onSearchCandidates(question, ans)
    })
  }, [question])

  useEffect(() => {
    if (topicId || !location.pathname.includes(routes.TEMP_SEARCH)) return

    const curTopicId = webAnswer?.data?.topic_id
    if (!curTopicId) return

    //append topic id for temp search
    setSearchParams((params) => {
      params.append('topic_id', curTopicId)
      setNewSearchTag()
      return Object.fromEntries(params)
    })
  }, [topicId, webAnswer])

  //use historical answer
  useEffect(() => {
    if (!cachedAnswer) return
    responseId.current = cachedAnswer?.response_id
    // const candidates = cachedAnswer.candidates ?? []
    // setCandidates(
    //   candidates
    //     .map((item) => {
    //       //TOOD: remove it after BE supports obj
    //       if (typeof item === 'string')
    //         return {
    //           kid: item,
    //         }

    //       return item
    //     })
    //     .slice(0, 1)
    // )
    const answers =
      cachedAnswer.reply?.map((item) => {
        return {
          data: item,
        }
      }) || []
    setAnswers(answers)
    answers[0] && setCurrentAnswer(answers[0])
  }, [cachedAnswer])

  const sendImportRewardHandler = async () => {
    // const res = await apis.rewardQuotaRouteApiQuotaRewardPost()
  }
  const searchContextValue = React.useMemo(() => {
    return {
      loading,

      candidates,
      hasNextAnswer: currentAnswerIndex < candidates.length,
      hasPrevAnswer: currentAnswerIndex > 0,
      currentAnswerIndex,
      goNextAnswer,
      backToPrevAnswer,

      currentAnswer,
      loadingAnswer: loadingWA || loadingKA,
      currentTopic,
      question,
      failGetNext,
      sendImportRewardHandler,
    }
  }, [
    loading,
    question,
    candidates,
    currentTopic,
    currentAnswer,

    failGetNext,

    backToPrevAnswer,
    goNextAnswer,

    loadingWA,
    loadingKA,

    currentAnswerIndex,
  ])

  return searchContextValue
}
